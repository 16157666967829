/* General Utils */

.pointer {
  cursor: pointer;
}

.padding-topbar {
  padding-top: var(--topbar-height);
}

.flex-1 {
  flex: 1;
}

.bg-white {
  background-color: #fff;
}

.bg-light-grey {
  background-color: #f4f4f4;
}

.text-black-15 {
  color: #151515
}

/* Tables Utils */

.border-radius-left-20 {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.border-radius-right-20 {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.table {
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: #151515;
}

.table>tbody>tr>td {
  padding-top: 16px;
  padding-bottom: 16px;
}

/* Buttons Utils */

.btn-outline-secondary,
.btn-secondary {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  color: var(--secondary) !important;
  background: var(--light-gray) !important;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 17px;
}

/* Forms utils */

.label-input {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* color: var(--primary); */
  margin-bottom: 8px;
}

.input-field {
  height: 51px;
  background: rgba(177, 177, 177, 0.2);
  padding-left: 20px;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.is-invalid {
  border: 1px solid red;
}

.input-field::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.input-field:focus-visible {
  outline: 1px solid #084897;
  border-radius: none;
}

.form-check-label {
  margin-left: 5px;
  margin-top: 3px;
}

.form-check-input {
  background-color: white;
  border-color: var(--primary);
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%0d4995%27/%3e%3c/svg%3e") !important;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:hover {
  background: rgba(177, 177, 177, 0.2);
  border-color: var(--primary);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%0d4995%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--secondary);
  border-color: var(--secondary);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
  cursor: pointer;
}

.form-check-input:checked:hover {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
  cursor: pointer;
}

.pill {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid var(--primary);
  padding: 5px 10px;
  color: #fff;
}

.pill-outline {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #151515;
  padding: 5px 10px;
  color: #151515;
}

.pill-outline-primary {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid var(--primary);
  padding: 5px 10px;
  color: var(--primary);
}

.pill-outline-primary-no-hover {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid var(--primary);
  padding: 5px 10px;
  color: var(--primary);
}

.pill-outline-primary:hover {
  background: rgba(177, 177, 177, 0.2) !important;
}

/* Modal Styles */

.modal-sm {
  --bs-modal-width: 660px;
}

.ModalTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--primary);
  width: 100%;
}

.ModalHeader {
  width: 100%;
  border-bottom: 0px;
  padding-top: 30px;
}

.ModalBody {
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.ModalFooter {
  display: flex;
  justify-content: space-between;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 40px;
  padding-bottom: 30px;
  border-color: var(--secondary);
}

.ModalBodySmall {
  padding-left: 130px;
  padding-right: 130px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.ModalBodyNoPadding {
  padding-left: 30px;
  padding-right: 30px;
}

.ModalFooterWithoutBorder {
  display: flex;
  justify-content: space-between;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 0px;
  padding-bottom: 30px;
  border: none;
}

.ModalFooterNoPadding {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 30px;
  border: none;
}

.TextModal {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #151515;
}

/* Alert style */

.alert {
  border-radius: 0px;
}

.alert-danger {
  border: 1px solid #f83a3a;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f83a3a;
}

.alert-transparent {
  border: 1px solid white;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

/* Dropzone */

.dropzone {
  text-align: center;
  padding: 20px;
  border: 1px var(--secondary) dashed;
  width: 100%;
  margin-top: 18px;
  border-radius: 12px;
  height: 226px;
  /* margin: auto; */
}

.dropzone>div>p>.hover-word:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dropzone-content {
  color: var(--secondary);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.max-size-upload {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #151515;
  margin-top: 8px;
}

.name-file-upload {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #084897;
}

/* React select  */

.metadata-filter__control {
  border-radius: 20px !important;
  width: 208px;
  border-color: var(--primary) !important;
}

.metadata-filter__control:hover {
  background: rgba(177, 177, 177, 0.2);
}

.metadata-filter__control--is-focused {
  border-radius: 20px !important;
  width: 208px;
  border-color: var(--primary) !important;
}

.metadata-filter__menu {
  border: 1px solid var(--primary);
  border-radius: 20px !important;
}

.metadata-filter__control--menu-is-open {
  border-radius: 20px !important;
  width: 208px;
  border-color: var(--primary) !important;
}

.metadata-filter__option {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  color: var(--primary) !important;
}

.metadata-filter__option--is-focused {
  font-weight: 400;
  font-size: 14px !important;
  background: none !important;
  text-decoration: underline;
  line-height: 17px;
  color: var(--primary) !important;
}

.metadata-filter__option--is-selected {
  font-weight: 500;
  font-size: 14px !important;
  background: none !important;
  text-decoration: underline;
  color: var(--primary) !important;
  line-height: 17px;
  display: flex !important;
  justify-content: space-between;
}

.metadata-filter__option--is-selected::after,
.metadata-filter__option--is-selected::after {
  content: "";
  background-image: url("./assets/icons/check-multiselect.svg");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  display: flex;
  height: 14px;
  width: 14px;
  align-items: center;
  justify-content: end;
}

.metadata-filter__indicator-separator {
  display: none;
}

.metadata-filter__dropdown-indicator>svg {
  display: none;
}

.metadata-filter__control--menu-is-open>div>.metadata-filter__dropdown-indicator {
  transform: rotate(180deg);
}

.metadata-filter__dropdown-indicator {
  padding: 0 !important;
  content: "";
  border-top: 0.3em solid var(--primary);
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  margin-right: 10px;
  transform: rotate(0px);
  border-left: 0.3em solid transparent;
}

.metadata-filter__single-value {
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  color: var(--primary) !important;
}

.metadata-filter__placeholder {
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  color: var(--primary) !important;
}

/* Pagination */

.page-link {
  padding: 5px 15px;
  gap: 10px;
  background: white;
  border: 0.5px solid var(--primary);
  border-radius: 5px;
  margin-right: 6px;
}

.matching-class {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 78px;
  padding-left: 5px;
  margin-top: -16px !important;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 50px;
}

.unmatching-class {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 78px;
  padding-left: 5px;
}

.placheholder-matching {
  width: 78px;
  padding-left: 5px;
}

/* 
Bordering */

.border-secondary-opacity {
  border-color: rgba(248, 164, 100, 0.3) !important;
}

.upload-ok {
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--primary);
}

.checkbox-container {
  border: 1px solid var(--primary);
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
}

.checkbox-container:hover {
  background-color: var(--light-gray);
}

.checkbox-container-secondary {
  border: 1px solid var(--secondary);
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
}

.checkbox-container-white {
  border: 1px solid white;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
}

.icon-hover:hover {
  width: 28px;
  height: 28px;
  background: rgba(177, 177, 177, 0.2);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-hover {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__month-container {
  font-family: "Inter", sans-serif !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--primary) !important;
}

.react-datepicker__day--selected {
  background-color: var(--primary) !important;
}

.hange-device-text {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
}

.table-fixed>.table {
  table-layout: fixed;
}

.w-1-secondary {
  width: 1px;
  background-color: rgba(248, 164, 100, 0.3);
  height: 100%;
}

.not-found {
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  color: var(--primary);
  margin-top: 12px;
}

.modal-backdrop {
  background: rgba(28, 28, 28, 0.30) !important;
  backdrop-filter: blur(10px);
}
.modal-backdrop.show {
  opacity: 1 !important;
}

.modal {
  z-index: 1050 !important;
}