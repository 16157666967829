.Home {
  padding-top: calc(var(--topbar-height));
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.BoxSearch {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  background: var(--primary);
  box-shadow: 0px 4px 4px rgba(8, 72, 151, 0.3);
  display: flex;
  align-items: center;
  gap: 8px;
}

.SearchBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  gap: 8px;
}

.SearchInput {
  font-weight: 300;
  font-size: 14px;
  display: block;
  line-height: 17px;
  padding: 10px 0 10px 0;
  height: 40px;
  color: var(--primary);
  border: none;
  flex: 1 1 0%;
}

.BlockSearchButton {
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.SearchInputActive {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 0 10px 0;
  height: 40px;
  color: var(--primary);
  border: none;
  flex: 1 1 0%;
}

.CancelSearch {
  cursor: pointer;
  top: 10px;
  left: 10px;
}

.SearchInput::placeholder {
  color: #4d76b4;
}

.SearchInput:focus-visible,
.SearchInputActive:focus-visible {
  outline: none;
}

.ButtonAdvancedFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 42px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  color: #FFF;
  border: 1px solid #FFF;
  background-color: transparent;
  box-sizing: border-box;
  border: 2px solid transparent;
  box-shadow: 0 0 0 1px #FFF;
  transition: box-shadow 0.2s ease-in-out;
}

.ButtonAdvancedFilters:hover {
  background-color: transparent;
  box-shadow: 0 0 0 2px #FFF;
}

.ButtonFiltersOpen {
  position: absolute;
  top: 446px;
  background-color: var(--secondary);
  width: 125px;
  transition: top 0.2s ease;
  height: 37px;
  border-radius: 0px 0px 10px 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  z-index: 100;
}

.ButtonFiltersOpen:hover,
.ButtonFilters:hover {
  background-color: var(--secondary-light);
}

.ButtonFilters {
  position: absolute;
  top: 82px;
  background-color: var(--secondary);
  width: 125px;
  height: 37px;
  border-radius: 0px 0px 10px 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
}

.BlockFilters {
  position: absolute;
  height: calc(100vh - 55px - 74px);
  background: rgba(28, 28, 28, 0.30);
  backdrop-filter: blur(10px);
  top: calc(74px);
  left: 0px;
  right: 0px;
  z-index: 4;
  transform-origin: top;
  transition: opacity 0.2s ease;
  z-index: 1101;
  /* transition: opacity 0.4s ease; */
}

.BlockFiltersContent {
  position: absolute;
  left: 50px;
  right: 50px;
  background-color: white;
  transform-origin: top;
  transition: transform 0.2s ease;
  padding: 18px 18px 0px 32px;
  min-height: 350px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}


.AdvancedFiltersTitle {
  color: var(--primary);
  text-align: start;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.292px;
  margin-bottom: 18px;
}

.NavLink {
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  background-color: transparent;
  text-decoration: none;
  border-radius: 100%;
  color: var(--primary) !important;
  height: 32px;
}

.SearchIcon {
  flex-shrink: 0;
}

.NavLink:hover>button {
  background: rgba(177, 177, 177, 0.2) !important;
}

.NavLink>button {
  margin-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 187px;
}

.ActiveSearch {
  color: var(--primary) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Divider {
  background-color: var(--secondary);
  width: 1px;
  height: 31px;
}

.ResetDates {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--secondary);
}

.ResetDates:hover {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--secondary);
  text-decoration: underline;
  cursor: pointer;
}

.ResetDatesDisabled {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--secondary);
  opacity: 0.3;
}

.SearchButton {
  background-color: var(--secondary);
  padding: 10px 20px;
  gap: 10px;
  width: 93px;
  height: 40px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  border: none;
  text-align: center;
}

.MatchingResults {
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: var(--black-15);
  display: flex !important;
  align-items: center;
  cursor: pointer;
  padding: 0 0 0 2.5rem;
}

.MatchingResults>input {
  cursor: pointer;
}

.LabelFilter {
  font-weight: 600;
  color: #151515;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.SwitchFilter {
  margin-top: 20px;
  display: flex;
}

.ContainerSwitch {
  display: flex;
  width: max-content;
  cursor: pointer;
  border: 1px solid var(--primary);
  border-radius: 20px;
  background-color: white;
}

.ContainerSwitch:hover {
  background: rgba(177, 177, 177, 0.2);
}

.ActiveItem {
  border-radius: 20px;
  font-family: var(--ideal-sans);
  font-style: normal;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.291667px;
  background-color: var(--primary);
  color: white;
}

.Item {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
  text-align: center;
  letter-spacing: -0.291667px;
}

.RT {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.InputRT {
  background-color: white;
  padding: 10px;
  width: 135px;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #084897;
  border-radius: 4px;
  border: 1px solid rgba(177, 177, 177, 0.20);
}

.InputRtTollerance {
  background-color: white;
  padding: 10px 10px 10px 10px;
  width: 135px;
  height: 38px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #084897;
  border-radius: 4px;
  border: 1px solid rgba(177, 177, 177, 0.20);
}

.InputMass {
  background-color: white;
  padding: 10px;
  width: 194px;
  height: 38px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #084897;
  border-radius: 4px;
  border: 1px solid rgba(177, 177, 177, 0.20);
}

.InputRT:hover,
.InputStudyKey:hover,
.InputMass:hover,
.InputRtTollerance:hover {
  background: rgba(177, 177, 177, 0.2);
}

.InputRTDate:hover {
  background-color: rgba(177, 177, 177, 0.2);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M16 3.95605C16.2449 3.95609 16.4813 4.04601 16.6644 4.20877C16.8474 4.37153 16.9643 4.5958 16.993 4.83905L17 4.95605V5.95605H19C19.5046 5.9559 19.9906 6.14646 20.3605 6.48955C20.7305 6.83264 20.9572 7.3029 20.995 7.80605L21 7.95605V19.9561C21.0002 20.4606 20.8096 20.9466 20.4665 21.3166C20.1234 21.6866 19.6532 21.9132 19.15 21.9511L19 21.9561H5C4.49542 21.9562 4.00943 21.7656 3.63945 21.4226C3.26947 21.0795 3.04284 20.6092 3.005 20.1061L3 19.9561V7.95605C2.99984 7.45148 3.19041 6.96549 3.5335 6.59551C3.87659 6.22553 4.34684 5.9989 4.85 5.96105L5 5.95605H7V4.95605C7.00028 4.70118 7.09788 4.45602 7.27285 4.27069C7.44782 4.08535 7.68695 3.97382 7.94139 3.95888C8.19584 3.94395 8.44638 4.02673 8.64183 4.19032C8.83729 4.35391 8.9629 4.58596 8.993 4.83905L9 4.95605V5.95605H15V4.95605C15 4.69084 15.1054 4.43648 15.2929 4.24895C15.4804 4.06141 15.7348 3.95605 16 3.95605ZM19 12.9561H5V19.9561H19V12.9561ZM19 7.95605H5V10.9561H19V7.95605Z" fill="%23084897"/></svg>');
  background-position-x: 96%;
  background-position-y: 45%;
}

.InputRTDate {
  padding: 10px;
  cursor: pointer;
  width: 192px;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #084897;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M16 3.95605C16.2449 3.95609 16.4813 4.04601 16.6644 4.20877C16.8474 4.37153 16.9643 4.5958 16.993 4.83905L17 4.95605V5.95605H19C19.5046 5.9559 19.9906 6.14646 20.3605 6.48955C20.7305 6.83264 20.9572 7.3029 20.995 7.80605L21 7.95605V19.9561C21.0002 20.4606 20.8096 20.9466 20.4665 21.3166C20.1234 21.6866 19.6532 21.9132 19.15 21.9511L19 21.9561H5C4.49542 21.9562 4.00943 21.7656 3.63945 21.4226C3.26947 21.0795 3.04284 20.6092 3.005 20.1061L3 19.9561V7.95605C2.99984 7.45148 3.19041 6.96549 3.5335 6.59551C3.87659 6.22553 4.34684 5.9989 4.85 5.96105L5 5.95605H7V4.95605C7.00028 4.70118 7.09788 4.45602 7.27285 4.27069C7.44782 4.08535 7.68695 3.97382 7.94139 3.95888C8.19584 3.94395 8.44638 4.02673 8.64183 4.19032C8.83729 4.35391 8.9629 4.58596 8.993 4.83905L9 4.95605V5.95605H15V4.95605C15 4.69084 15.1054 4.43648 15.2929 4.24895C15.4804 4.06141 15.7348 3.95605 16 3.95605ZM19 12.9561H5V19.9561H19V12.9561ZM19 7.95605H5V10.9561H19V7.95605Z" fill="%23084897"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 45%;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgba(177, 177, 177, 0.20);
}

.InputRT::placeholder,
.InputRTDate::placeholder,
.InputStudyKey::placeholder {
  color: rgba(77, 118, 180, 0.8);
}

.InputRtTollerance::placeholder,
.InputMass::placeholder {
  color: rgba(77, 118, 180, 0.8);
}

.InputStudyKey {
  background-color: white;
  padding: 10px;
  width: 208px;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #084897;
  border-radius: 4px;
  border: 1px solid rgba(177, 177, 177, 0.20);
}

.ValueFilterMasses {
  font-weight: 600;
}

.FiltersActive {
  margin-left: -30px;
  margin-top: -5px;
}

.DropdownMenu {
  border: 1px solid var(--primary);
  border-radius: 20px;
}

.DropdownItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.DropdownItem:hover {
  background: none;
  text-decoration: underline;
  color: var(--primary);
}

.FilterNumberSelect {
  width: 29px;
  height: 21px;
  background: var(--primary);
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ItemsLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
  margin-left: 10px;
}

.ItemsLabelPlaceholder {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.NumberResults {
  font-weight: 700;
}

.NotFound {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary);
  margin-top: 12px;
}

.InteractPanel {
  margin-top: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #151515;
}

.TolleranceContainer {
  position: relative;
}

.Unit {
  position: absolute;
  display: block;
  left: 15px;
  color: var(--primary);
  top: 10px;
  z-index: 9;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}


.Menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(245, 130, 43, .3);
  width: 100%;
  padding-left: 47px;
  padding-right: 47px;
  box-sizing: border-box;
}

.MenuItem {
  padding: 12px 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.MenuItemActive {
  border-bottom: 4px solid var(--secondary);
  padding: 12px 8px;
  padding-bottom: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.MenuItem:hover {
  border-bottom: 2px solid var(--secondary);
  box-sizing: border-box;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1339px) {
  .BlockFiltersContent {
    background-color: white;
    padding: 18px 18px 0px 32px;
  }

  .InputRT {
    width: 135px;
  }
  .InputRtTollerance {
    width: 135px;
  }
  .InputMass {
    width: 194px;
  }

  .InputRTDate {
    width: 192px;
  }
}

@media only screen and (min-width: 1500px) {
  .BlockFiltersContent {
    background-color: white;
    transform-origin: top;
    transition: transform 0.2s ease;
    padding: 18px 18px 0px 32px;
    min-height: 350px;
  }
}