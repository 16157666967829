.TooltipBox {
    display: flex;
    max-width: 300px;
    width: fit-content;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid var(--basic-dark-grey, rgba(177, 177, 177, 0.20));
    background: var(--basic-white, #FFF);
    color: var(--basic-black, #151515);
    font-feature-settings: 'liga' off, 'clig' off;
    margin-top: 4px
}

.TextOverlay:hover {
    border-radius: 100px;
    background: var(--basic-light-grey, #F4F4F4);
    display: flex;
    max-width: fit-content;
    padding: 4px;
    margin: -4px;
    align-items: center;
    gap: 4px;
    color: var(--primary);
    cursor: pointer;
}

.copyIcon {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
    flex-shrink: 0
}

.copyIcon:hover {
    transform: scale(1.25);
}