.ResetPassword {
    padding-top: var(--topbar-height);
    height: 100%;
    display: flex;
  }
  
  .LeftBox {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 0px 100px;
    background-image: url('../../assets/bg.svg');
    background-size: cover;
  }
  
  .RightBox {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
  }
  
  .Title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  
  .LabelLogin {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  
  .Input {
    display: block;
    width: 100%;
    background-color: #4d76b4;
    height: 50px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
  }

  .InputDisabled {
    display: block;
    width: 100%;
    background-color: #4d76b4;
    border: 1px solid white !important;
    height: 50px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
  }
  
  .Input:focus {
    background-color: #ffffff;
    border: 1px solid #142a69;
    color: var(--primary);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    border-radius: none;
  }
  
  .Input:focus-visible {
    border: none;
    outline: none;
  }
  
  .Input::placeholder {
    color: white;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  
  .RecoverPassword {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #ffffff;
    cursor: pointer;
  }
  