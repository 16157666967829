@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "variables";
@import "~bootstrap/scss/bootstrap";

:root {
  --topbar-height: 55px;
  --primary: #084897;
  --secondary: #f5822b;
  --secondary-light: #f7954b;
  --light-gray: #f4f4f4;
  --black-15: #151515  
}

body,
html,
#root {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: black;
}

code {
  font-family: "Inter", sans-serif;
}

.app-title {
  font-size: 160px;
  font-weight: 900;
  margin-top: 0;
  margin-left: -4px;
  letter-spacing: -0.4rem;
}

.app-subtitle {
  font-size: 28px;
  margin-bottom: 160px;
}