.NoDataBackground {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 180px);
  background-color: rgba(28, 28, 28, 0.30);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.NoDataBox {
  width: 662px;
  height: 398px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px rgba(21, 21, 21, 0.10);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 135px
}

.NoDataAxes {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.ContentPage {
  padding-right: 50px;
  padding-left: 50px;
  height: 100%;
  width: 100%;
  display: flex;
}

.FilterSection {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 25%;
  min-width: 280px;
  min-height: 390px;
  flex-shrink: 0;
  padding-top: 16px;
  padding-right: 20px;
  padding-bottom: 18px;
  border-right: 1px solid rgba(245, 130, 43, 0.3);
  height: 100%;
}

.ChartSection {
  width: 75%;
  height: 100%;
  overflow: hidden;
}

.TopFilter {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  flex-shrink: 0;
  gap: 30px;
  width: 100%;
}

.Ppm {
  display: flex;
  flex-direction: column;
  height: 24px;
  width: 40%;
  padding: 0px 20px 0px 10px;
  justify-content: center;
  align-items: start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(177, 177, 177, 0.20);
  white-space: nowrap;
  position: relative;
}

.Ppm input {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--primary);
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 8px;
  bottom: 0;
}

.Ppm input::-webkit-outer-spin-button,
.Ppm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.Ppm input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.PpmDa {
  display: flex;
  padding: 4px 8px;
  width: 40%;
  justify-content: start;
  align-items: baseline;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5rem;
  background-color: #F4F4F4;
  white-space: nowrap;
}

.Dropdown {
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  background-color: transparent;
  text-decoration: none;
  color: var(--primary) !important;
  height: 32px;
  max-width: 125px;
  width: 125px;
  white-space: nowrap;
}

.Dropdown:hover>button {
  background: rgba(177, 177, 177, 0.2) !important;
  border: 1px solid var(--primary)
}

.Dropdown>button {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 125px;
  width: 125px;
  border: 1px solid var(--primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DropdownToggle {
  color: var(--primary) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--primary);
}

.DropdownMenu {
  border: 1px solid var(--primary);
  border-radius: 20px;
}

.DropdownItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.DropdownItem:hover {
  background: none;
  text-decoration: underline;
  color: var(--primary);
}

.Tooltip {
  display: flex;
  width: 227px;
  padding: 14px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid rgba(177, 177, 177, 0.20);
  background: #FFF;
  backdrop-filter: blur(2px);
}

.TooltipLabel {
  color: var(--basic-black, #151515);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.TooltipText {
  color: var(--basic-black, #151515);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.TooltipPop {
  border-radius: 0px 0px 0px 3px;
  border-bottom: 1px solid var(--basic-dark-grey, rgba(177, 177, 177, 0.20));
  border-left: 1px solid var(--basic-dark-grey, rgba(177, 177, 177, 0.20));
  background: var(--basic-white, #FFF);
  backdrop-filter: blur(2px);
  width: 14.92px;
  height: 14.778px;
  flex-shrink: 0;
}

.ZoomIn {
  padding: 8px;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid var(--secondary);
  background: #FFF;
}

.ZoomIn:hover {
  outline: 1px solid var(--secondary);
}

.ZoomOut {
  padding: 8px;
  border: 1px solid var(--secondary);
  background: #FFF;
  box-sizing: border-box;
}

.ZoomOut:hover {
  outline: 1px solid var(--secondary);
}

.ZoomAiming {
  padding: 8px;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid var(--secondary);
  background: #FFF;
}

.ZoomAiming:hover {
  outline: 1px solid var(--secondary);
}

.DownloadImage {
  text-decoration: underline;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.DownloadImage:hover {
  text-decoration: underline;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14.52px;
}