.TableHome {
  padding-left: 50px;
  padding-right: 50px;
}

.LegendMasses {
  margin-left: 55px;
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.FeedbackChipRed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 48px;
  padding: 2px 6px;
  gap: 4px;
  border-radius: 100px;
  box-shadow: inset 0 0 0 1px #F09A86;
  cursor: pointer;
}

.FeedbackChipRed:hover {
  box-shadow: inset 0 0 0 2px #F09A86
}

.FeedbackChipRedFilled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px;
  min-width: 48px;
  gap: 4px;
  border-radius: 100px;
  background-color: #F09A86;
  cursor: pointer;
}

.FeedbackChipGreyFilled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px;
  min-width: 48px;
  gap: 4px;
  border-radius: 100px;
  background-color: rgba(177, 177, 177, 0.2);
  cursor: pointer;
}

.FeedbackChipBlueFilled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px;
  gap: 4px;
  border-radius: 100px;
  background-color: var(--primary);
  cursor: pointer;
}

.FeedbackChipYellow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px;
  min-width: 48px;
  gap: 4px;
  border-radius: 100px;
  box-shadow: inset 0 0 0 1px #F0D586;
  cursor: pointer;
}

.FeedbackChipYellow:hover {
  box-shadow: inset 0 0 0 2px #F0D586
}

.FeedbackChipYellowFilled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px;
  min-width: 48px;
  gap: 4px;
  border-radius: 100px;
  background-color: #F0D586;
  cursor: pointer;
}

.Identification,
.Notes,
.Cas {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.Paginator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #FFF;
  padding-top: 8px;
  padding-bottom: 16px;
  padding-left: 50px;
  padding-right: 50px;
}


.Dropdown {
  color: #FFF;
  border: none;
  padding-left: 6px;
  margin: 0px;
  max-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Dropdown:hover {
  padding: 0px;
  border: none;
  padding-left: 6px;
}

.Dropdown::after {
  display: none;
}

.Dropdown>button::after {
  display: none;
}

.Dropdown>button {
  padding: 0px;
  color: #FFF;
  border: none;
}

.Dropdown:hover>button {
  padding: 0px;
  color: #FFF;
  background-color: var(--primary);
  border: none;
}

.Dropdown>button {
  padding: 0px;
  color: #FFF;
  background-color: var(--primary);
  border: none;
}

.Dropdown>button:active,
.Dropdown>button:hover {
  padding: 0px;
  color: #FFF;
  background-color: var(--primary) !important;
  border: none;
}

.Dropdown>div {
  margin-top: 10px;
  margin-left: -10px;
  z-index: 100 !important;
}

.DropdownMenu {
  border: 1px solid var(--primary);
  border-radius: 20px;
  background-color: white;
  margin-left: -15px !important;
}

.DropdownItem {
  display: flex !important;
  align-items: center;
  gap: 4px;
  color: var(--primary) !important;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.DropdownItem:hover,
.DropdownItem:focus,
.DropdownItemActive {
  background: none;
  text-decoration: underline;
  color: var(--primary);
}

.DropdownItem:active {
  color: var(--primary);
}

.DropdownHeader {
  color: var(--black-15);
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.TextAreaField {
  display: block;
  min-height: 40px;
  width: 100%;
  background: rgba(177, 177, 177, 0.2);
  padding: 8px 12px;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: var(--black-15);
  resize: none;
  margin: 0;
}

.TextAreaField::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.TextAreaField:focus-visible {
  outline: none !important
}

.FeedbackBox {
  border: 1px solid rgba(177, 177, 177, 0.2);
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.FeedbackComment {
  color: #151515;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.TitleModal {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  color: var(--primary);
}

.TextEmail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;
}

.TextModal {
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.TextBoldModal {
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.GradientTopShadow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  height: 20px;
  width: 100%;
  position: absolute;
  top: 0;
}

.GradientBottomShadow {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.TooltipFeedback {
  display: flex;
  width: 142px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--basic-dark-grey, rgba(177, 177, 177, 0.20));
  background: var(--basic-white, #FFF);
  color: var(--basic-black, #151515);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px
}