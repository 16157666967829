.DataManagement {
  padding-top: calc(var(--topbar-height) + 38px);
  padding-left: 50px;
  padding-right: 50px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary);
}

.SearchInput {
  border: 1px solid var(--primary);
  border-radius: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 36px;
  width: 279px;
  color: #4d76b4;
  background: url("../../assets/icons/search.svg") no-repeat scroll 10px 10px;
}

.HighlightRow {
  background-color: rgba(77, 118, 180, 0.1);
  transition: none;
}

.StudyRow {
  transition: background-color 500ms ease-in-out;
}

.NumberResults {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.CheckedItemsForDelete {
  padding: 5px 10px 5px 10px;
  border: 1px solid var(--primary);
  border-radius: 20px;
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 17px;
  color: var(--primary);
  width: 200px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NotFound {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary);
  margin-top: 12px;
}

.InteractPanel {
  margin-top: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #151515;
}
