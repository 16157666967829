#upload-tray {
  position: fixed;
  right: 10px;
  bottom: 0;
  width: 308px;
}

#upload-tray .body {
  max-height: 198px;
  overflow-y: auto;
  border: 1px solid rgba(33, 33, 33, 0.15);
  border-radius: 0px;
  padding: 20px 10px;
  background-color: white;
  width: 308px;
}

.header {
  background-color: var(--primary);
  box-shadow: 1px 1px 4px 1px rgba(21, 21, 21, 0.2);
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NameFileUpload {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--primary);
  margin-left: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
