.title-h1 {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0
}

.title-h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: 'liga' off, 'clig' off;
  margin: 0
}

.title-h3 {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0
}

.paragraph-sm {
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paragraph-sm-semibold {
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.paragraph-sm-bold {
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.paragraph-md {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.paragraph-md-bold {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-button-sm {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-button-md {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-button-md-semibold {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}