.StickyTable {
  position: relative;
  overflow-y: auto;
}

.StickyTable a {
  color: #000000;
}

.StickyTable th {
  position: sticky;
  top: 0;
  background-color: var(--primary);
}

.StickyTable thead {
  border: 0;
  height: 35px;
}

.StickyTable thead > tr > th {
  color: white;
  border: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background-color: var(--primary);
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
}

.StickyTable thead > tr > :after {
  content: ' ';
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.StickyTable table > tbody tr:last-child > td {
  border-bottom: 0px !important;
}

.StickyTable table > tbody {
  border-top: 0 !important;
}

.StickyTable table {
  margin-bottom: 0 !important;
}

.FixedLayout{
  table-layout: fixed;
}