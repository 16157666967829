.userStatistics {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.banner {
  display: flex;
  width: 100%;
  padding: 12px 48px;
  padding-top: calc(var(--topbar-height) + 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  background: var(--primary);
}

.filterSection {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  width: 25%;
  min-width: 248px;
  flex-shrink: 0;
}

.flatStat {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 25%;
  min-width: 248px;
  margin-right: 64px;
}

.boxStat {
  display: flex;
  min-width: 35%;
  width: 100%;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  background-color: #FFF;
}

.content {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  padding-top: 26px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 34px;
}

.rangeWidth {
  width: 34px;
}

.divider {
  width: 1px;
  height: 100%;
  opacity: 0.3;
  background-color: var(--secondary);
  margin-right: 24px;
  margin-left: 30px;
}

.tooltipHitRatio {
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid rgba(177, 177, 177, 0.20);
  background: #FFF;
}

.chartContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}