.TopBar {
  height: var(--topbar-height);
  box-shadow: 0px 3px 6px rgba(184, 184, 184, 0.301328);
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 16px;
  position: fixed;
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  z-index: 100;
}

.LabelDashboard {
  color: var(--secondary);
  font-weight: 400;
  font-size: 14px;
  margin-top: 3px;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
}

.LabelDashboardActive , .LabelDashboard:hover {
  color: var(--secondary);
  font-weight: 600;
  font-size: 14px;
  margin-top: 3px;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
}

.LabelDashboardActive:hover {
  color: var(--secondary);
}

.BlockLogo {
  display: flex;
  align-items: center;
}

.BorderLogo {
  width: 1px;
  height: 20px;
  background-color: #4d76b4;
  margin-left: 10px;
  margin-top: 3px;
}

.NavLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border: 0.5px solid var(--primary);
  text-decoration: none;
  border-radius: 100px;
  padding: 5px 15px;
  margin-left: 10px;
}

.NavLink:hover {
  background: rgba(177, 177, 177, 0.2);
  color: var(--primary);
}

.NavLinkActive {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background-color: var(--primary);
  text-decoration: none;
  border-radius: 100px;
  border: 0.5px solid var(--primary);
  padding: 5px 15px;
  color: white;
  margin-left: 10px;
}

.NavLinkActive:hover {
  color: white;
}

.NavLink > button {
  padding: 0px;
  color: var(--primary);
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.NavLink:hover > button {
  padding: 0px;
  color: var(--primary);
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.NavLink > div {
  margin-top: 10px;
  margin-left: -10px;
  z-index: 100 !important;
}

.DropdownMenu {
  border: 1px solid var(--primary);
  border-radius: 20px;
  background-color: white;
  margin-left: -15px !important;
}

.DropdownItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.DropdownItem:hover, .DropdownItem:focus, .DropdownItemActive{
  background: none;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
}

.DropdownItem:active {
  color: var(--primary);
}
