.AccountManagement {
  padding-top: calc(var(--topbar-height) + 38px);
  padding-left: 50px;
  padding-right: 50px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary);
}

.SearchInput {
  border: 1px solid var(--primary);
  border-radius: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 36px;
  width: 279px;
  color: #4D76B4;
  background: url("../../assets/icons/search.svg") no-repeat scroll 10px 10px;
}


.NumberResults {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary)
}

.NotFound {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary);
  margin-top: 12px;
}

.InteractPanel {
  margin-top: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #151515;
}
