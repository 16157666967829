.MessageSent {
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--primary);
}

.TextForgot {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #151515;
}
